import React from "react";
import {usePage} from "../../../../../Provider/PageProvider";

interface Props {

}

export default function ShippingContainerV2(props: Props) {
    const {upgradeShippingProducts, selectedProduct, cartTotalWoShipping, currencySymbol} = usePage();

    const items = upgradeShippingProducts.filter(f => {
        return !(selectedProduct && f.product.id === selectedProduct.id) && f.triggerTotal > cartTotalWoShipping;
    });

    if (!items || items.length === 0) {
        return (
            <div className={"shipping-v2"}>
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="32" height="32" rx="16" fill="#1BAF30"/>
                    <path d="M21.9998 9L13.8164 17.0909L10.0004 13.3347L7 16.289L13.8164 23L25 11.9544L21.9998 9Z"
                          fill="white"/>
                </svg>

                <span>CONGRATS!<br/>
You Qualify For Free Shipping</span>
            </div>
        )
    }

    const left = Math.round(items[0].triggerTotal - cartTotalWoShipping);

    return (
        <div className={"shipping-v2 red"}>
            <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="#F06565"/>
                <line x1="11" y1="11" x2="21" y2="21" style={{stroke: "#fff", strokeWidth: 3}} />
                <line x1="21" y1="11" x2="11" y2="21" style={{stroke: "#fff", strokeWidth: 3}} />
            </svg>
            <span>
                ALMOST THERE<br/>
                Add {currencySymbol}{left} To Unlock Free Shipping
            </span>
        </div>
    )
}
