// @ts-nocheck
import React, {Fragment} from "react";
import {usePage} from "../../../../Provider/PageProvider";
import {usePageView} from "../../../../Provider/PageViewProvider";
import PlaceOrderImg from "../../../../Assets/place_order.png";

export default function PlaceOrder() {
    const {registerEvent} = usePageView();
    const {completeOrder, isProcessing} = usePage();

    const onPress = () => {
        registerEvent('place_order', 1);
        completeOrder();
    }

    return (
        <Fragment>
            <p>
                <button type="button" onClick={onPress}><i
                    className="icon-lock"></i> {isProcessing ? "Submitting..." : "Place the order"}</button>
            </p>
            <p className={"place-order-img"}>
                <img src={PlaceOrderImg} alt={""}/>
            </p>
        </Fragment>
    )
}
