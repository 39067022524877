import React, {Fragment, useEffect, useState} from "react";
import {isMobileOnly} from 'react-device-detect';

interface Props {
    minutes: number;
}

export default function CountDown({minutes}: Props) {
    const [isShow, setShow] = useState(true);

    const zeroPad = (value: number | string, length: number = 2): string => {
        const strValue = String(value);
        if (length === 0) return strValue;
        const match = strValue.match(/(.*?)([0-9]+)(.*)/);
        const prefix = match ? match[1] : '';
        const suffix = match ? match[3] : '';
        const strNo = match ? match[2] : strValue;
        const paddedNo =
            strNo.length >= length
                ? strNo
                : ([...Array(length)].map(() => '0').join('') + strNo).slice(length * -1);
        return `${prefix}${paddedNo}${suffix}`;
    };

    const [seconds, setSeconds] = useState(minutes * 60);

    const onMinutesChange = () => {
        const timer = setInterval(() => {
            if (seconds <= 0) {
                clearInterval(timer);
            } else {
                setSeconds(seconds => seconds - 1);
            }
        }, 1000);
        return () => {
            clearInterval(timer);
        }
    }

    useEffect(onMinutesChange, [minutes]);

    useEffect(() => {
        if (seconds <= 0) {
            setShow(false);
        }
    }, [seconds]);

    const minutesToDie = Math.floor(seconds / 60);
    const secondsToDie = seconds - (minutesToDie * 60);

    if (!isShow) {
        return <Fragment/>;
    }

    return (
        <Fragment>
            <p className="scheme-sticky" style={isMobileOnly ? {} : {
                zIndex: 1000,
                position: 'fixed',
                top: 0,
                margin: '0 auto',
                left: 0, right: 0,
            }}>
                <span className="strong text-uppercase">Limited time offer:&nbsp;</span>
                <span className="mobile-hide">Complete your order before it's too late!&nbsp;</span>
                Your cart is reserved for <span className="medium">{minutes} minutes</span>.
                <span className="countdown">{zeroPad(minutesToDie, 2)}:{zeroPad(secondsToDie, 2)}</span>

                <div className="progress">
                    <div style={{animationDuration: (minutes * 60) + 's'}}></div>
                </div>
            </p>
            {!isMobileOnly &&
            <div style={{
                display: 'block',
                width: '100%',
                height: 71,
                float: 'none'
            }}></div>
            }
        </Fragment>
    )
}
