import React, {Fragment} from "react";
import StripeCheckoutWrapper from "./StripeCheckoutWrapper";
import {usePage} from "../../../../../Provider/PageProvider";

import CreditCardImage from "../../../../../Assets/creditcards.png";
import PaypalImage from "../../../../../Assets/paypal.png";
import PaypalCheckoutWrapper from "./PaypalCheckoutWrapper";
import {usePageView} from "../../../../../Provider/PageViewProvider";


export default function CheckoutCard() {
    const {registerEvent} = usePageView();
    const {paymentMethod, setPaymentMethod, cardNumberRef} = usePage();

    const paymentSwitch = (method: string) => {
        setPaymentMethod(method);
        registerEvent('payment_set_' + method, 1);
    }

    return (
        <Fragment>
            <h2 ref={cardNumberRef}>Step 4: <span>Payment Information</span></h2>
            <ul className="list-payment">
                <li>
                    <input type="radio" id="stripe" name="payment-method" checked={paymentMethod === 'credit'}
                           onClick={() => paymentSwitch('credit')}/> <label htmlFor="stripe">Credit Card</label>
                    <div className="triple a">
                        {paymentMethod === 'credit' && <StripeCheckoutWrapper active={paymentMethod === 'credit'}/>}
                    </div>
                    <img src={CreditCardImage} alt="" width="172" height="15"/>
                </li>
                <li><input type="radio" id="paypal-radio" name="payment-method" checked={paymentMethod === 'paypal'}
                           onClick={() => paymentSwitch('paypal')}/> <label htmlFor="paypal-radio">PayPal</label>
                    {paymentMethod === 'paypal' && <PaypalCheckoutWrapper active={paymentMethod === 'paypal'}/>}
                    <div>
                        <p className={"color-dove"}>
                            After clicking "Place the Order", you will be redirected to PayPal to complete your purchase
                            securely.
                        </p>
                    </div>
                    <img src={PaypalImage} alt="" width="88" height="25"/></li>
            </ul>
        </Fragment>
    )

}
