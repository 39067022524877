import React, {Fragment} from "react";
import StarRating from "./Stars/StarRating";
import {usePage} from "../../../../Provider/PageProvider";

export interface IReview {
    name: string;
    description: string;
    rating: number;
    image: string;
}

export default function Reviews() {
    const {reviews} = usePage();
    // const reviews = [
    //     {
    //         name: 'Emma Merrick',
    //         description: 'I’m using the CatSizor nail grinder on my British shorthair. It was so quiet and soothing that she fell asleep. I couldn’t believe it! Finally, it doesn\'t cause any stress for my cat (Actually, she HATED the nail-trimming sessions we had previously!). It is significantly quieter than other products that I have bought. I am super happy with this amazing grinder! I wish I heard about this sooner.',
    //         rating: 5,
    //         image: 'https://checkout.catsizor.com/static-files/catsizor/reviews/cs_emma.png'
    //     },
    //     {
    //         name: 'James Drawson',
    //         description: 'It saved me hundreds of dollars already...I don’t need to bring my cat to the vet for grooming anymore. With CatSizor I get the same results at home. Amazing! I love the guard that helps to ensure you don\'t grind too close. I’m very satisfied!',
    //         rating: 5,
    //         image: 'https://checkout.catsizor.com/static-files/catsizor/reviews/cs_james.png'
    //     },
    //     {
    //         name: 'Lillian Wanders',
    //         description: 'There is no need to worry about damaged furniture, curtains, and carpets...Believe me, it was a headache and a huge expense... CatSizor nail grinder is the perfect solution to avoid those mischiefs... After each grinding, it leaves smooth edges of my cat’s claws. My house stays safe, and my hands too! I strongly recommend it to every pet owner!',
    //         rating: 5,
    //         image: 'https://checkout.catsizor.com/static-files/catsizor/reviews/cs_lillian.png'
    //     }
    // ];

    if (!reviews) {
        return <Fragment/>
    }

    return <Fragment>
        <h2>Reviews</h2>
        <ul className="list-reviews">
            {reviews.map((item: IReview, index: number) => {


                return (
                    <li key={"review-" + index}>
                        <h3>
                            <img src={item.image} alt={item.name} width="50" height="50"/> {item.name}
                            <StarRating rating={item.rating}/>
                        </h3>
                        <p>{item.description}</p>
                    </li>
                )
            })}
        </ul>
    </Fragment>
}
