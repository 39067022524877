import React, {Fragment} from "react";
import CountDown from "./Components/Header/CountDown";
import Reviews from "./Components/Content/Reviews";
import UpgradeOrder from "./Components/Content/UpgradeOrder/UpgradeOrder";
import OrderQuantity from "./Components/Content/Order/OrderQuantity";
import ListTotal from "./Components/Content/Cart/ListTotal";
import CustomerInformation from "./Components/Form/CustomerInformation";
import DeliveryAddress from "./Components/Form/DeliveryAddress";
import PlaceOrder from "./Components/Content/PlaceOrder";
import CheckoutCard from "./Components/Content/Checkout/CheckoutCard";
import {usePage} from "../../Provider/PageProvider";
import ShippingContainerV2 from "./Components/Content/Shipping/ShippingContainerV2";
import HeaderComponent from "./Components/Header/HeaderComponent";

export default function CheckoutV21() {
    const {selectedProduct} = usePage();
    return (
        <Fragment>
            <CountDown minutes={10}/>
            <HeaderComponent/>

            <main id="content">
                {/*<nav className="module-nav">*/}
                {/*    <FeaturesList/>*/}
                {/*</nav>*/}
                <form action=".." method="post" className="form-cart">
                    <OrderQuantity/>
                    {!!selectedProduct &&
                    <Fragment>
                        <CustomerInformation/>
                        <DeliveryAddress/>
                        <CheckoutCard/>


                        <div className="double a b40">
                            <div>
                                <UpgradeOrder/>
                            </div>
                            <div>
                                {/*<ShippingContainer className={"module-box small blank"} wide={true}/>*/}

                                <ShippingContainerV2/>

                                {/*<div className="module-box small blank">*/}
                                {/*    <div>*/}
                                {/*        <h2>Free shipping on orders over $XX</h2>*/}
                                {/*        <p>To get free shipping, just upgrade to X pcs</p>*/}
                                {/*    </div>*/}
                                {/*    <p className="link-btn"><a href=".." className="wide small">Upgrade to X pcs</a></p>*/}
                                {/*</div>*/}
                                <ListTotal/>
                                <PlaceOrder/>
                            </div>
                        </div>
                        <Reviews/>
                    </Fragment>
                    }

                </form>
            </main>

        </Fragment>
    )
}
