import Config from "../Constants/Config";
import {Address, Contact} from "../Types/CheckoutTypes";
import {CartTotalItem} from "../Types/Objects";
import Cookies from 'js-cookie';

class PagesApiService {
    constructor() {
        this._token = '';
    }

    private _token: string;

    set token(value: string) {
        this._token = value;
    }

    defaultRequestOptions = () => {
        return {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': this._token
            },
        }
    };

    getPage(url: string, selectedProduct: number) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({url, selectedProduct})
        };

        return fetch(Config.backendUrl + '/app/shared/pages/info', requestOptions).then(res => res.json());
    }

    saveContactForm(url: string, contact: Contact) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({url, ...contact, fbc: Cookies.get('_fbc'), fbp: Cookies.get('_fbp')})
        };

        return fetch(Config.backendUrl + '/app/shared/pages/saveContactForm', requestOptions).then(res => res.json());
    }

    makeOrder(url: string, contact: Contact, address: Address, items: CartTotalItem[], paymentMethod: any, extraData: any) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({
                url, ...contact, ...address,
                items,
                paymentMethod,
                extraData,
                fbc: Cookies.get('_fbc'),
                fbp: Cookies.get('_fbp')
            })
        };

        return fetch(Config.backendUrl + '/app/shared/pages/makeOrder', requestOptions).then(res => res.json());
    }

    makeUpsaleOrder(url: string, items: CartTotalItem[], paymentMethod: any, id: string,) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({url, items, paymentMethod, id})
        };

        return fetch(Config.backendUrl + '/app/shared/pages/makeUpsaleOrder', requestOptions).then(res => res.json());
    }

    getDetails(orderId: string) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({orderId})
        };

        return fetch(Config.backendUrl + '/app/shared/pages/getDetails', requestOptions).then(res => res.json());
    }


    setPayment(orderId: string, status: string, paymentInfo: any) {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({orderId, status, paymentInfo})
        };

        return fetch(Config.backendUrl + '/app/shared/pages/setPayment', requestOptions).then(res => res.json());
    }

    setFavicon = (url: string) => {
        const favicon = document.getElementById("favicon");
        // @ts-ignore
        favicon.href = url;
    }

    checkZip = (code: string, country: string) => {
        const requestOptions = {
            ...this.defaultRequestOptions(),
            body: JSON.stringify({code, country})
        };

        return fetch(Config.backendUrl + '/app/shared/pages/checkZip', requestOptions).then(res => res.json());
    }
}

const PagesApi = new PagesApiService();
export default PagesApi;
