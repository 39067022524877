import React, {Fragment, useEffect, useState} from "react";
import {Product, ProductExtraImage} from "../../../../../Types/Objects";
import StarRating from "../Stars/StarRating";
import {useCart} from "../../../../../Provider/CartProvider";
import {usePageView} from "../../../../../Provider/PageViewProvider";

interface Props {
    product: Product,
    removeFromCartItem: (product: Product) => void,
    isInCart: boolean,
    changeCartItemQuantity: (int: number, productId: number) => void,
    currencySymbol: string,
}

export default function UpsaleProduct({product, removeFromCartItem, changeCartItemQuantity, isInCart, currencySymbol}: Props) {

    const [rating, setRating] = useState('');
    const [votes, setVotes] = useState('');

    const {registerEvent} = usePageView();
    const [quantity, setQuantity] = useState(1);
    const {addCartItem} = useCart();

    const addQuantity = () => {
        setQuantity(quantity + 1);

        registerEvent('upsale_product_increment', product.id);
    }
    const minQuantity = () => {
        const newQ = Math.max(1, quantity - 1);
        setQuantity(newQ);

        registerEvent('upsale_product_decrease', product.id);
    }

    const onQunatityChange = () => {
        if (!isInCart && quantity > 1) {
            addCartItem(product, quantity);
        } else {
            changeCartItemQuantity(quantity, product.productId);
        }
    }

    useEffect(onQunatityChange, [quantity]);

    const description = !!product.description ? product.description.split("\n") : [];
    const extraDescription = !!product.extraDescription ? product.extraDescription.split("\n") : [];

    const setRatingVotes = () => {
        setRating(((Math.floor(Math.random() * 5) + 46) / 10).toFixed(1));
        setVotes(((Math.floor(Math.random() * 3916) + 1032) / 1000).toFixed(3).replace('.', ','));
    }
    useEffect(setRatingVotes, []);

    return (
        <form action="./" method="post" className="module-product">
            <div>
                <figure>
                    <img src={product.image} alt={product.title} width="360" height="270"/>
                </figure>
                <p className="price">{currencySymbol}{product.price.toFixed(2)} <span>Reg {currencySymbol}{product.regularPrice.toFixed(2)}</span></p>
                <p>
                    <label htmlFor="mpa">Quantity</label>
                    <div className={"semantic-number"}>
                        <input type="number" id="mpa" name="mpa" value={quantity.toString()}
                               onChange={e => setQuantity(parseInt(e.target.value, 10))} required/>
                        <a href="/plus" onClick={e => {
                            e.preventDefault();
                            addQuantity()
                        }} className="btn plu" role="button">+</a>
                        <a href="/min" onClick={e => {
                            e.preventDefault();
                            minQuantity()
                        }} className="btn min" role="button">-</a>
                    </div>
                </p>
            </div>
            <div>
                <header>
                    <h2>{product.title}
                        {product.isPopular &&
                        <span className="overlay-b"><i className="icon-flame"></i> Most popular</span>}


                    </h2>
                    <StarRating rating={5} asP={true} label={rating + " Rating | " + votes + " Votes"}/>
                </header>
                {description.map((text: string, index: number) => {
                    const className = index === 0 ? "strong m0" : "";
                    return (<p className={className}>{text}</p>);
                })}

                {extraDescription.length > 0 &&
                <ul>
                    {extraDescription.map((text: string) => {
                        return (<li>{text}</li>);
                    })}
                </ul>
                }
                <ul className="list-features">
                    {product.extraImages.map((img: ProductExtraImage) => {
                        return (
                            <li><span className="img">
                                <img src={img.img} alt={img.title} width="64"
                                     height="64"/></span> {img.title}
                            </li>
                        )
                    })}
                </ul>
            </div>
            <footer>
                <p>
                    {!isInCart ?
                        <button type="button" onClick={() => {
                            addCartItem(product, quantity);

                            registerEvent('upsale_product_add', product.id);
                        }}><i
                            className="icon-arrow-right"></i> Yes, Add this to My Order <i
                            className="icon-arrow-left"></i></button>
                        : <Fragment>
                            <button type="reset" onClick={() => {
                                removeFromCartItem(product);
                                registerEvent('upsale_product_remove', product.id);
                            }}><i
                                className="icon-x-circle"></i> Remove from cart
                            </button>
                            <span>Scroll down to continue</span>
                        </Fragment>
                    }
                </p>
            </footer>
        </form>
    )
}
