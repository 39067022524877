import React from "react";
import {usePage} from "../../../../../Provider/PageProvider";
import {CartTotalItem} from "../../../../../Types/Objects";

interface Props {
    type?: string;
}

export default function ListTotal({type}: Props) {
    let ulClassName = ["list-total"];
    if (type) {
        ulClassName.push("overlay-" + type);
    }

    const {cartItems, cartTotal, currencySymbol} = usePage();

    return (
        <ul className={ulClassName.join(' ')}>
            <li className="header">Item <span>Amount</span></li>
            {cartItems.map((item: CartTotalItem, index: number) => {
                return <li key={"cart-key-" + index}>{item.title} <span>{currencySymbol}{(item.price * item.quantity).toFixed(2)}</span></li>
            })}

            <li className="strong">Total <span>{currencySymbol}{cartTotal.toFixed(2)}</span></li>
        </ul>
    )
}
