import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {BumpProduct, CartTotalItem, Page, Product, UpgradeOrderProduct, UpgradeShippingProduct} from "../Types/Objects";
import PagesApi from "../Service/PagesApiService";
import CheckoutV21 from "../Themes/GreenPixel/CheckoutV21";
import CheckoutV22 from "../Themes/GreenPixel/CheckoutV22";
import CheckoutV23 from "../Themes/GreenPixel/CheckoutV23";
import {Address, Contact} from "../Types/CheckoutTypes";
import PaymentsApi from "../Service/PaymentsApiService";
import {IReview} from "../Themes/GreenPixel/Components/Content/Reviews";
import {loadStripe} from "@stripe/stripe-js";
import FooterNeedHelpComponent from "../Themes/GreenPixel/Components/Content/Footer/FooterNeedHelpComponent";
import {usePageView} from "./PageViewProvider";
// @ts-ignore
import getSymbolFromCurrency from 'currency-symbol-map'

export interface SelectUpgradeProductCart {
    product: UpgradeOrderProduct,
    quantity: number,
}

interface ProviderValue {
    products: Product[],
    upgradeProducts: UpgradeOrderProduct[],
    upgradeShippingProducts: UpgradeShippingProduct[],

    selectedProduct: Product | null,
    setSelectedProduct: (val: Product) => void,

    selectedBumpProduct: BumpProduct | null,
    setSelectedBumpProduct: (val: BumpProduct | null) => void,

    selectedUpgradeProducts: SelectUpgradeProductCart[],
    addSelectedUpgradeProduct: (val: UpgradeOrderProduct, q: number) => void,
    removeSelectedUpgradeProduct: (val: UpgradeOrderProduct) => void,

    onAddressChange: (key: string, val: string) => void,
    onContactChange: (key: string, val: string) => void,

    contact: Contact | null,
    address: Address | null,

    cartItems: CartTotalItem[],
    cartTotal: number,
    cartTotalWoShipping: number,

    completeOrder: () => void,

    emailFieldRef: any,
    cardNumberRef: any,

    paymentMethod: string,
    setPaymentMethod: (val: string) => void,

    saveContactDetails: () => void,

    checkoutAction: any,
    setCheckoutAction: (checkoutAction: any) => void,

    makeOrder: (paymentMethod: string, extraData: any) => void,

    formError: string,
    setFormError: (val: '') => void,

    isProductChanged: boolean,
    setProductChanged: (val: boolean) => void,

    design: number,
    mainLogoUrl: string,

    isProcessing: boolean,

    reviews: IReview[],
    stripePromise: any,

    currency: string,
    currencySymbol: string,
}

export const PageContext = React.createContext<ProviderValue>({
    products: [],
    upgradeProducts: [],
    upgradeShippingProducts: [],

    selectedProduct: null,
    setSelectedProduct: (val: Product) => {
    },

    selectedBumpProduct: null,
    setSelectedBumpProduct: (val: BumpProduct | null) => {
    },

    selectedUpgradeProducts: [],
    addSelectedUpgradeProduct: (val: UpgradeOrderProduct, q: number) => {
    },
    removeSelectedUpgradeProduct: (val: UpgradeOrderProduct) => {
    },

    onAddressChange: (key: string, val: string) => {
    },
    onContactChange: (key: string, val: string) => {
    },

    contact: null,
    address: null,

    cartItems: [],
    cartTotal: 0,
    cartTotalWoShipping: 0,

    completeOrder: () => {
    },

    emailFieldRef: null,
    cardNumberRef: null,

    paymentMethod: 'credit',
    setPaymentMethod: (val: string) => {
    },

    saveContactDetails: () => {
    },

    checkoutAction: null,
    setCheckoutAction: (checkoutAction: any) => {
    },

    makeOrder: (paymentMethod: any, extraData: any) => {
    },

    formError: '',
    setFormError: (val: '') => {
    },

    isProductChanged: false,
    setProductChanged: (val: boolean) => {
    },

    design: 0,
    mainLogoUrl: '',

    isProcessing: false,

    reviews: [],

    stripePromise: null,

    currency: '',
    currencySymbol: '',
});

export const usePage = () => useContext(PageContext);

interface Props {
    url: string;
}

export const PageProvider = ({url}: Props) => {
    const {onPageView} = usePageView();

    const [isProcessing, setIsProcessing] = useState(false);

    const [stripePromise, setStripePromise] = useState<any>();

    const [isProductChanged, setProductChanged] = useState(false);
    const [checkoutAction, setCheckoutAction] = useState<any>(null);
    const [paymentMethod, setPaymentMethod] = useState('credit');
    const [selectedUpgradeProducts, setSelectedUpgradeProducts] = useState<SelectUpgradeProductCart[]>([]);
    const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
    const [selectedBumpProduct, setSelectedBumpProduct] = useState<BumpProduct | null>(null);
    const [pageData, setPageData] = useState<Page | null>(null);

    const [cartItems, setCartItems] = useState<CartTotalItem[]>([]);
    const [cartTotal, setCartTotal] = useState(0);
    const [cartTotalWoShipping, setCartTotalWoShipping] = useState(0);

    const [shippingPrice, setShippingPrice] = useState(0);

    const [formError, setFormError] = useState<string>('');

    const emailFieldRef = useRef();
    const cardNumberRef = useRef();

    const [contact, setContact] = useState<Contact>({
        firstName: '',
        lastName: '',
        email: '',
        phone: ''
    });
    const [address, setAddress] = useState<Address>({
        city: '',
        country: '',
        state: '',
        street: '',
        zip: ''
    });


    const calcCartTotal = () => {

        let items: CartTotalItem[] = [];
        if (selectedProduct) {
            items.push({
                productId: selectedProduct.productId,
                title: selectedProduct.cartTitle,
                price: selectedProduct.price,
                quantity: 1,
            })
        }
        if (selectedBumpProduct) {
            items.push({
                productId: selectedBumpProduct.productId,
                title: selectedBumpProduct.cartTitle,
                price: selectedBumpProduct.price,
                quantity: 1,
            })
        }

        selectedUpgradeProducts.map((item: SelectUpgradeProductCart) => {
            let price = item.product.price;
            item.product.priceItems.map((p) => {
                if (item.quantity >= p.quantity) {
                    price = p.price;
                }
            });

            let title = item.product.title;
            if (item.quantity > 1) {
                title = item.quantity + "x " + item.product.title;
            }

            items.push({
                productId: item.product.productId,
                title: title,
                price: price,
                quantity: item.quantity,
            })
            return item;
        })

        const cartTotalWoShipping = items && items.length > 0 ? items.map(item => item.price * item.quantity).reduce((prev, next) => prev + next) : 0;

        const shippingItems = pageData && (selectedProduct || selectedUpgradeProducts.length > 0) ? pageData.upgradeShippingProducts.filter(f => {
            return !(selectedProduct && f.product.id === selectedProduct.id) && f.triggerTotal > cartTotalWoShipping;
        }) : [];

        if (shippingItems.length > 0) {
            shippingItems.map(item => {
                items.push({
                    productId: -1,
                    title: "Shipping",
                    price: shippingPrice > 0 ? shippingPrice : item.shippingTotal,
                    quantity: 1,
                });
                return item;
            })
        } else {
            items.push({
                productId: -1,
                title: "Shipping",
                price: 0,
                quantity: 1,
            });
        }

        setCartItems(items);
        setCartTotal(items && items.length > 0 ? items.map(item => item.price * item.quantity).reduce((prev, next) => prev + next) : 0);
        setCartTotalWoShipping(cartTotalWoShipping);
    }

    useEffect(calcCartTotal, [selectedProduct, selectedBumpProduct, selectedUpgradeProducts, pageData?.upgradeShippingProducts, shippingPrice]);

    const checkShippingPrice = () => {
        let newShipping = 0;
        if (pageData && pageData._shipping) {
            if (address && address.country) {
                if (pageData._shipping[address.country]) {
                    newShipping = pageData._shipping[address.country];
                }
            }
        }
        if (newShipping !== shippingPrice) {
            setShippingPrice(newShipping);
        }
    }

    useEffect(checkShippingPrice, [pageData, address.country]);

    const onAddressChange = (key: string, val: string) => {
        let addressNew = JSON.parse(JSON.stringify(address));
        if (key === 'multiple') {
            const newData = JSON.parse(val);
            const keys = Object.keys(newData);
            keys.map((_key: string) => {
                addressNew[_key] = newData[_key];
                return key;
            })
        } else {
            // @ts-ignore
            addressNew[key] = val;
        }
        setAddress(addressNew);
    }
    const onContactChange = (key: string, val: string) => {
        let contactNew = JSON.parse(JSON.stringify(contact));
        // @ts-ignore
        contactNew[key] = val;
        setContact(contactNew);
    }

    const getData = () => {
        PagesApi.getPage(url, 0).then(res => {
            setPageData(res.data);
            const selProduct: Product = res.data.products[0];
            setSelectedProduct(selProduct);
            if (selProduct.bumpProduct && selProduct.bumpProduct.price === 0) {
                setSelectedBumpProduct(selProduct.bumpProduct);
                setProductChanged(true);
            }


            try {
                // @ts-ignore
                window.gtag('event', 'add_to_cart', {
                    "items": [
                        {
                            'id': 'product-' + selProduct.productId,
                            'name': selProduct.title,
                            'price': selProduct.price,
                            'quantity': 1
                        }
                    ]
                });
            } catch (e) {

            }

        })
    }

    const onPageLoad = () => {
        if (pageData) {

            onPageView({
                allScripts: pageData.allScripts,
                allScriptsUrl: pageData.allScriptsUrl,
                css: pageData.css,
                favicon: pageData.favicon,
                fontUrl: pageData.fontUrl,
                pageScripts: pageData.orderPageScripts,
                pageScriptsUrl: pageData.orderPageScriptsUrl,
                title: pageData.title
            }, 'Cart');
        }
    }
    useEffect(onPageLoad, [pageData]);

    const setUpStripe = () => {
        if (pageData && pageData.stripeKey) {
            setStripePromise(loadStripe(pageData.stripeKey));
        }
    }
    useEffect(setUpStripe, [pageData?.stripeKey]);

    const updateData = () => {

    }

    const saveContactDetails = () => {
        PagesApi.saveContactForm(url, contact).then(() => {

        })
    }

    useEffect(getData, [url]);

    const onSelectProduct = () => {
        if (selectedBumpProduct !== null) {
            if (!!selectedProduct && !!selectedProduct.bumpProduct) {
                setSelectedBumpProduct(selectedProduct.bumpProduct);
            } else {
                setSelectedBumpProduct(null);
            }
        }
        if (selectedProduct) {
            updateData();
        }
    }

    useEffect(onSelectProduct, [selectedProduct]);

    const addSelectedUpgradeProduct = (val: UpgradeOrderProduct, q: number) => {
        const products = [...selectedUpgradeProducts.filter(i => i.product !== val), {product: val, quantity: q}];
        setSelectedUpgradeProducts(products);
    };
    const removeSelectedUpgradeProduct = (val: UpgradeOrderProduct) => {
        const products = [...selectedUpgradeProducts.filter(i => i.product !== val)];
        setSelectedUpgradeProducts(products);
    }


    const scrollToRef = (ref: any) => {
        if (ref && ref.current) {
            ref.current.scrollIntoView({behavior: 'smooth', block: 'start'})
        }
    }

    useEffect(() => {
    }, [checkoutAction]);

    useEffect(() => {
        if (formError.length > 0) {
            scrollToRef(cardNumberRef);
            setIsProcessing(false);
        }
    }, [formError]);


    // const gtag = () => {
    //     // @ts-ignore
    //     window.dataLayer = window.dataLayer || [];
    //     // @ts-ignore
    //     window.dataLayer.push({
    //         event: 'pageview',
    //         page: {
    //             url: window.location.href,
    //             title: "Cart"
    //         }
    //     });
    // }
    // useEffect(gtag, []);

    if (!pageData) {
        return <Fragment/>
    }

    const getDesign = (design: number) => {
        switch (design) {
            case 1:
                return <CheckoutV21/>
            case 2:
                return <CheckoutV22/>
            case 3:
                return <CheckoutV23/>
        }
    }

    const completeOrder = () => {
        if (!isProcessing) {
            const event = new Event('checkError');
            document.dispatchEvent(event);

            if (!contact.email) {
                scrollToRef(emailFieldRef);
                return;
            }
            setIsProcessing(true);
            PaymentsApi.completeOrder();
        }
    }

    const makeOrder = (paymentMethod: any, extraData: any) => {
        // setIsProcessing(false);
        return PagesApi.makeOrder(url, contact, address, cartItems, paymentMethod, extraData);
    }


    return (
        <PageContext.Provider value={{
            products: pageData.products,
            upgradeProducts: pageData.upgradeProducts,
            upgradeShippingProducts: pageData.upgradeShippingProducts,
            selectedProduct,
            setSelectedProduct: (val: Product) => {
                setSelectedProduct(val);
                setProductChanged(true)
            },
            selectedBumpProduct,
            setSelectedBumpProduct,
            selectedUpgradeProducts,
            addSelectedUpgradeProduct,
            removeSelectedUpgradeProduct,

            onAddressChange,
            onContactChange,
            contact,
            address,

            cartItems,
            cartTotal,
            cartTotalWoShipping,

            completeOrder,

            emailFieldRef,
            cardNumberRef,

            paymentMethod,
            setPaymentMethod,

            saveContactDetails,

            checkoutAction,
            setCheckoutAction,

            makeOrder,

            formError,
            setFormError,

            isProductChanged,
            setProductChanged,

            design: pageData.design,
            mainLogoUrl: pageData.mainLogoUrl,

            isProcessing: isProcessing,

            reviews: pageData.reviews,
            stripePromise: stripePromise,

            currency: pageData.currency,
            currencySymbol: getSymbolFromCurrency(pageData.currency)
        }}>

            {getDesign(pageData.design)}
            <FooterNeedHelpComponent email={pageData.email}/>

        </PageContext.Provider>
    )
};
