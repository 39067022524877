import React, {Fragment} from "react";
import Field from "./Fields/Field";
import {usePage} from "../../../../Provider/PageProvider";

export default function CustomerInformation() {
    const {contact, onContactChange, emailFieldRef} = usePage();

    if (!contact) {
        return <Fragment/>
    }
    return <Fragment>
        <div ref={emailFieldRef} style={{marginTop: -30, marginBottom: 30, lineHeight: 0}}>&nbsp;</div>

        <h2>Step 2: <span>Your Information</span></h2>
        <div className="double a">
            <Field val={contact.firstName} onChange={onContactChange} title={"First name"} fieldKey={"firstName"}
                   required={true}
                   errorText={"Please enter your first name"}/>
            <Field val={contact.lastName} onChange={onContactChange} title={"Last name"} fieldKey={"lastName"}
                   required={true}
                   errorText={"Please enter your last name"}/>
        </div>
        <div className="double a">
            <Field val={contact.email} onChange={onContactChange} title={"Email Address"} fieldKey={"email"}
                   required={true} tipText={"Please make sure your email address is valid."}
                   errorText={"Please enter a valid email address"} type={"email"}/>
            <Field val={contact.phone} onChange={onContactChange} title={"Phone Number"} fieldKey={"phone"}
                   required={true}/>
        </div>
    </Fragment>
}
