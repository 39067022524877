import React from "react";
import {Product, UpsaleCartTotalItem} from "../../../../../Types/Objects";
import {usePageView} from "../../../../../Provider/PageViewProvider";


interface Props {
    item: UpsaleCartTotalItem,
    removeFromCartItem: (product: Product) => void,
    changeCartItemQuantity: (int: number, productId: number) => void,
    currencySymbol: string,
}

export default function UpsaleCartItem({item, removeFromCartItem, changeCartItemQuantity, currencySymbol}: Props) {
    const {registerEvent} = usePageView();
    const addQuantity = () => {
        changeCartItemQuantity(item.quantity + 1, item.product.productId);

        registerEvent('upsale_cart_increment', item.product.id);
    }
    const minQuantity = () => {
        const newQ = Math.max(1, item.quantity - 1);
        changeCartItemQuantity(newQ, item.product.productId);

        registerEvent('upsale_cart_decrease', item.product.id);
    }

    return (
        <li>
            <a href="/" onClick={(e) => {
                e.preventDefault()
            }}>{item.product.cartTitle}</a>
            <a href="/remove" onClick={e => {
                e.preventDefault();
                removeFromCartItem(item.product);

                registerEvent('upsale_cart_remove', item.product.id);
            }} className="overlay-d"><i className="icon-x-circle"/> Remove</a>
            <label/>

            <div className={"semantic-number"}>
                <input type="number" id="mpa" name="mpa" value={item.quantity.toString()}
                       onChange={e => changeCartItemQuantity(parseInt(e.target.value, 10), item.product.productId)}
                       required/>
                <a href="/plus" onClick={e => {
                    e.preventDefault();
                    addQuantity()
                }} className="btn plu" role="button">+</a>
                <a href="/min" onClick={e => {
                    e.preventDefault();
                    minQuantity()
                }} className="btn min" role="button">-</a>
            </div>


            <span className="price">{currencySymbol}{item.product.price.toFixed(2)}
                <span>Reg {currencySymbol}{item.product.regularPrice.toFixed(2)}</span></span>
        </li>
    )
}
