import React from "react";

interface Props {
    email?: string;
}

export default function FooterNeedHelpComponent(props: Props) {
    const {email} = props;

    const year = (new Date()).getFullYear();

    return (
        <footer id="footer">
            <p>&copy; <span className="date">{year}</span> Copyright</p>
            <p><a href={"mailto:" + email} ><i className="icon-chat"></i> Need help?</a></p>
        </footer>
    )
}
