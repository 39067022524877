import React, {Fragment} from "react";
import {BumpProduct, Product} from "../../../../../Types/Objects";
import {usePage} from "../../../../../Provider/PageProvider";
import {isMobileOnly} from 'react-device-detect';
import {usePageView} from "../../../../../Provider/PageViewProvider";

interface Props {
    product: BumpProduct;
    orderProduct: Product;
}

export default function ProductBump({product, orderProduct}: Props) {
    const {selectedBumpProduct, setSelectedBumpProduct, currencySymbol} = usePage();
    const {registerEvent} = usePageView();

    const onChange = () => {
        if (selectedBumpProduct === product) {
            registerEvent('deselect_bump', product.id);
            setSelectedBumpProduct(null);
        } else {
            setSelectedBumpProduct(product);
            registerEvent('select_bump', product.id);
        }
    }

    const selected = selectedBumpProduct === product;
    const type = selected ? 'c' : 'b';

    const bumpKey = "bump-product-" + product.id + "-" + product.productId + '-' + orderProduct.id;

    const productPriceComp = () => {
        const regPrice = <span>Reg {currencySymbol}{product.regularPrice.toFixed(2)}</span>;
        if (product.price === 0) {
            return (
                <span className="price" style={{textAlign: isMobileOnly ? 'left' : 'right'}}>Free {regPrice}</span>);
        }
        return <span className="price">Only {currencySymbol}{product.price.toFixed(2)} {regPrice}</span>
    }

    return (
        <Fragment>
            {
                isMobileOnly ?
                    <div className={"module-box overlay-" + type} style={{paddingRight: 0}} onClick={onChange}>
                        <Fragment>
                            <h4><i className="icon-check-shield"
                                   style={{bottom: -73, right: 0, top: "auto"}}></i>{product.title}
                            </h4>
                            <p className="check" style={{margin: "15px 0"}}>
                                <input type="checkbox" id={bumpKey} name={bumpKey} checked={selected}
                                       style={{marginTop: 4}}
                                />
                                <label>{product.checkboxTitle}</label>
                            </p>
                            <span className={"row"}>
                        {productPriceComp()}
                    </span>
                        </Fragment>
                    </div>
                    : <div className={"module-box overlay-" + type} onClick={onChange}>
                        <Fragment>
                            <h4><i className="icon-check-shield"></i> {product.title}</h4>
                            <p>{product.description}</p>
                            <p className="check">
                                <input type="checkbox" id={bumpKey} name={bumpKey} checked={selected}
                                />
                                <label>{product.checkboxTitle}</label>
                                {productPriceComp()}
                            </p>
                        </Fragment>
                    </div>
            }
        </Fragment>

    )
}
