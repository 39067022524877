import React, {Fragment, useEffect, useState} from "react";
import EmailImage from "../../../../../Assets/email-us.png";

import {useParams} from 'react-router-dom'
import PagesApi from "../../../../../Service/PagesApiService";
import {DetailsResponse} from "../../../../../Types/Upsale";
import HeaderBackground from "../Header/HeaderBackground";
import HeaderLogoTitle from "../Header/HeaderLogoTitle";
import FooterNeedHelpComponent from "../Footer/FooterNeedHelpComponent";
import {usePageView} from "../../../../../Provider/PageViewProvider";
import {CartTotalItem} from "../../../../../Types/Objects";
// @ts-ignore
import getSymbolFromCurrency from 'currency-symbol-map'

export default function ConfirmPage() {
    const {onPageView, registerEvent} = usePageView();

    const {serial} = useParams();
    const [data, setData] = useState<DetailsResponse>();

    useEffect(() => {
        PagesApi.getDetails(serial).then((res: DetailsResponse) => {
            if (res.success === 1) {
                setData(res);
                // console.log('res', res);
                PagesApi.setFavicon(res.data.favicon);
            }
        })
    }, [serial]);

    const onPageLoad = () => {
        if (data && data.data) {

            onPageView({
                allScripts: data.data.allScripts,
                allScriptsUrl: data.data.allScriptsUrl,
                css: data.data.css,
                favicon: data.data.favicon,
                fontUrl: data.data.fontUrl,
                pageScripts: data.data.thankYouScripts,
                pageScriptsUrl: data.data.thankYouScriptsUrl,
                title: data.data.title
            }, 'Confirmation');
            gtagPurchase();
        }
    }
    useEffect(onPageLoad, [data]);

    const gtagPurchase = () => {
        if (!!data && !!data.data) {
            const products = data.items.map((item: CartTotalItem) => {
                return {
                    'id': 'product-' + item.productId,
                    'name': item.title,
                    'price': item.price,
                    'quantity': item.quantity
                }
            })
            try {
                // // @ts-ignore
                // window.gtag('event', 'purchase', {
                //     "transaction_id": serial,
                //     "affiliation": "Checkout",
                //     "value": data.total / 100,
                //     "currency": "USD",
                //     "tax": 0,
                //     "shipping": 0,
                //     "items": products
                // });

                registerEvent('confirm_page', data.total / 100);

            } catch (e) {

            }
        }
    }
    // useEffect(gtagPurchase, [data?.items]);
    //
    // const gtag = () => {
    //     // @ts-ignore
    //     window.dataLayer = window.dataLayer || [];
    //     // @ts-ignore
    //     window.dataLayer.push({
    //         event: 'pageview',
    //         page: {
    //             url: window.location.href,
    //             title: "Thank you"
    //         }
    //     });
    // }
    // useEffect(gtag, []);

    if (!data) {
        return <Fragment/>;
    }

    const currencySymbol = getSymbolFromCurrency(data.data.currency);

    return (
        <Fragment>
            <HeaderBackground image={data.data.bg}/>
            <HeaderLogoTitle image={data.data.mainLogoUrl} title={"Thank you - Order Completed!"}/>

            <main id="content">
                <form className={"form-cart module-product"}>
                    <div style={{width: '100%'}}>
                        {/*<div className={"text-center"}><h1>Thank you - Order Completed!</h1></div>*/}
                        <div className={"text-center"}><h2>You Just Got A Great Deal!</h2></div>
                        <div className={"text-center"}><p className={"m0"}>Order receipt
                            NR.: <strong>{data.data.orderPrefix}-{serial.toUpperCase()}</strong>
                        </p>
                        </div>

                        {/*<div className={"text-center"}><h2>Payment Successfully Completed</h2></div>*/}
                        <br/>
                        <br/>
                        <h2><span>Your Product Receipt</span></h2>

                        {/*<nav className="module-nav">*/}
                        <div style={{padding: 10}}>
                            <ul className="list-total">
                                <li className="header">Item <span>Amount</span></li>
                                {data.items.map(item => {
                                    const q = !!item.quantity && item.quantity > 1 ? item.quantity + ' x ' : '';
                                    return (<li>{item.title} <span>{q}{currencySymbol}{item.price.toFixed(2)}</span></li>);
                                })}
                                <li className="strong">Total <span>{currencySymbol}{(data.total / 100).toFixed(2)}</span></li>
                            </ul>
                        </div>
                        {/*</nav>*/}
                    </div>
                </form>

                <form className={"form-cart module-product"}>
                    <div style={{width: '100%'}}>
                        <h2><span>Thank You for Your Order!</span></h2>

                        <div className={"fs-18"}>
                            <p className={"text-bold"}>A <u>confirmation email</u> with more information will also be
                                sent
                                to your email address!</p>
                            <p>P.S. If you don't see anything in your inbox, please don't forget to check your junk/spam
                                folder.</p>
                            <p>We hope you love your {data.data.productName},
                                however if you’re not completely satisfied, please reach out to us via email and we will
                                assist
                                you!
                            </p>
                            <p className={"text-bold"}>THANK YOU: We Reach Over 50,000+ Happy Customers in June
                                2020!</p>
                        </div>
                    </div>
                </form>

                <form className={"form-cart module-product"}>

                    <div className={"text-center"}>
                        <img src={EmailImage} width={150} height={"auto"} alt={""}/>
                    </div>
                    <div>
                        <h3>Got Questions?</h3>
                        <p>Need assistance regarding your order or anything about our products?<br/>Feel free to
                            email us {data.data.email}. We are more than willing to assistant you.</p>
                    </div>

                </form>
            </main>
            <br/>
            <br/>
            <FooterNeedHelpComponent email={data.data.email}/>

        </Fragment>
    )
}
