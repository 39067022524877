import React, {Fragment} from "react";
import ProductItem from "./ProductItem";
import {usePage} from "../../../../../Provider/PageProvider";

export default function OrderQuantity() {
    const {products} = usePage();
    // const products = [
    //     {
    //         bumpProduct: {
    //             title: 'Headline of Bump Product, to explain the benefit',
    //             description: 'Protect your order for one low price',
    //             regularPrice: '40.00',
    //             price: '15.00',
    //             checkboxTitle: 'Yes, I want 3 years protection',
    //             type: "b"
    //         },
    //         image: "http://placehold.it/220x165",
    //         title: "1x of Superfood Tabs I am the longer title and I am not yet sure how to render",
    //         description: "Starter Pack",
    //         isPopular: true,
    //         price: "100.00",
    //         regularPrice: "200.00"
    //     },
    //     {
    //         bumpProduct: {
    //             title: 'Headline of Bump Product, to explain the benefit',
    //             description: 'Protect your order for one low price',
    //             regularPrice: '40.00',
    //             price: '15.00',
    //             checkboxTitle: 'Yes, I want 3 years protection',
    //             type: "c"
    //         },
    //         image: "http://placehold.it/220x165",
    //         title: "2x of Superfood Tabs",
    //         description: "Value Pack",
    //         isPopular: false,
    //         price: "100.00",
    //         regularPrice: "200.00"
    //     }
    // ];

    return (
        <Fragment>
            <h2>Step 1: <span>Select Order Quantity</span></h2>
            <ul className="list-cart">
                {products.map(item => {
                    return <ProductItem key={"product-item-" + item.id} product={item}/>
                })}
            </ul>
        </Fragment>
    )
}
