import React from "react";
import {useHistory, useParams} from "react-router-dom";
import {usePageView} from "../../../../../Provider/PageViewProvider";

interface Props {
    className?: string,
}

export default function UpsaleCancelButton({className}: Props) {
    const {registerEvent} = usePageView();
    const text = "No, Thanks I don't want to take advantage of this exclusive offer";

    const history = useHistory();
    const {serial} = useParams();

    const goToThankYouPage = () => {
        history.push('/order/confirm/' + serial);

        registerEvent('upsale_cart_no_thanks', 1);
    }

    return (
        <p className={"s18 text-center text-uppercase strong " + (className ? className : '')}>
            <a className="overlay-b" href="/cancel" onClick={e => {
                e.preventDefault();
                goToThankYouPage();
            }}>{text}</a></p>
    )
}
