import React, {Fragment, useEffect, useState} from "react";
import {CartTotalItem, Product, UpsaleCartTotalItem} from "../../../../../Types/Objects";
import UpsaleProduct from "./UpsaleProduct";
import UpsaleCartItem from "./UpsaleCartItem";
import {useCart} from "../../../../../Provider/CartProvider";
import {useHistory, useParams} from "react-router-dom";
import {DetailsResponse} from "../../../../../Types/Upsale";
import PagesApi from "../../../../../Service/PagesApiService";
import FooterNeedHelpComponent from "../Footer/FooterNeedHelpComponent";
import HeaderBackground from "../Header/HeaderBackground";
import HeaderLogoTitle from "../Header/HeaderLogoTitle";
import UpsaleCancelButton from "./UpsaleCancelButton";
import {usePageView} from "../../../../../Provider/PageViewProvider";
// @ts-ignore
import getSymbolFromCurrency from 'currency-symbol-map'

export default function UpsaleContent() {
    const {onPageView, registerEvent} = usePageView();

    const [isProcessing, setIsProcessing] = useState(false);
    const {cartItems, changeCartItemQuantity, removeFromCartItem} = useCart();

    const history = useHistory();
    const {serial} = useParams();
    const [data, setData] = useState<DetailsResponse>();
    const getDetails = () => {
        PagesApi.getDetails(serial).then((res: DetailsResponse) => {
            if (res.success === 1) {
                if (res.data.products.length === 0) {
                    redirectToConfirm();
                } else {
                    setData(res);
                    PagesApi.setFavicon(res.data.favicon);
                }
            }
        })
    }
    useEffect(getDetails, []);

    const makeOrder = () => {
        setIsProcessing(true);
        if (data) {
            PagesApi.makeUpsaleOrder(data.data.url, cartItems.map((item: UpsaleCartTotalItem) => {
                return {
                    productId: item.product.productId,
                    title: item.product.cartTitle,
                    price: item.product.price,
                    quantity: item.quantity,
                }
            }), data.paymentMethod, serial).then((res: any) => {
                if (data.paymentMethod === 'paypal') {
                    if (res.success === 1) {
                        const links = res.data.result.links;
                        const approveLinks = links.filter((item: any) => item.rel === 'approve');
                        if (approveLinks.length > 0) {
                            window.location.href = approveLinks[0].href;
                        }
                    }
                } else {
                    if (res.success === 1) {
                        redirectToConfirm();
                    }
                }

            });
        }
    }

    const redirectToConfirm = () => {
        history.push('/order/confirm/' + serial);
    }

    const onPageLoad = () => {
        if (data && data.data) {

            onPageView({
                allScripts: data.data.allScripts,
                allScriptsUrl: data.data.allScriptsUrl,
                css: data.data.css,
                favicon: data.data.favicon,
                fontUrl: data.data.fontUrl,
                pageScripts: data.data.upsellScripts,
                pageScriptsUrl: data.data.upsellScriptsUrl,
                title: data.data.title
            }, 'Upsale');

            gtagPurchase();
        }
    }
    useEffect(onPageLoad, [data]);

    const gtagPurchase = () => {
        if (!!data && !!data.data) {
            const products = data.items.map((item: CartTotalItem) => {
                return {
                    'id': 'product-' + item.productId,
                    'name': item.title,
                    'price': item.price,
                    'quantity': item.quantity
                }
            })
            try {
                // @ts-ignore
                window.gtag('event', 'purchase', {
                    "transaction_id": serial,
                    "affiliation": "Checkout",
                    "value": data.total / 100,
                    "currency": "USD",
                    "tax": 0,
                    "shipping": 0,
                    "items": products
                });

                registerEvent('upsell_page', data.total / 100);
            } catch (e) {

            }
        }
    }
    // useEffect(gtagPurchase, [data?.items]);
    //
    // const gtag = () => {
    //     // @ts-ignore
    //     window.dataLayer = window.dataLayer || [];
    //     // @ts-ignore
    //     window.dataLayer.push({
    //         event: 'pageview',
    //         page: {
    //             url: window.location.href,
    //             title: "Upsell"
    //         }
    //     });
    // }
    // useEffect(gtag, []);

    if (!data) {
        return <Fragment/>;
    }

    const currencySymbol = getSymbolFromCurrency(data.data.currency);

    return (
        <Fragment>
            <HeaderBackground image={data.data.bg}/>
            <HeaderLogoTitle image={data.data.mainLogoUrl} title={"Power Up Your Order With These Amazing Add-ons"}/>

            <main id="content">

                {data.data.products.map((i: Product) => {
                    const isInCart = cartItems.filter((p: UpsaleCartTotalItem) => p.product.productId === i.productId).length > 0;
                    return (
                        <UpsaleProduct changeCartItemQuantity={changeCartItemQuantity} isInCart={isInCart} product={i}
                                       currencySymbol={currencySymbol}
                                       removeFromCartItem={removeFromCartItem}/>)
                })}

                {!!cartItems && cartItems.length === 0 &&
                <UpsaleCancelButton className={"m50"}/>
                }
                {cartItems.length > 0 &&
                <Fragment>
                    <form className="m40">
                        <h2>You Have Selected:</h2>
                        <ul className="list-cart-small">
                            {cartItems.map((item: UpsaleCartTotalItem) => {
                                return (
                                    <UpsaleCartItem changeCartItemQuantity={changeCartItemQuantity} item={item}
                                                    removeFromCartItem={removeFromCartItem} currencySymbol={currencySymbol}/>
                                )
                            })}

                        </ul>
                        <p>
                            <button type="button"
                                    onClick={makeOrder}>{isProcessing ? "Submitting..." : "Place the order"}</button>
                        </p>
                    </form>
                    <UpsaleCancelButton/>
                </Fragment>
                }
            </main>

            <FooterNeedHelpComponent email={data.data.email}/>

        </Fragment>
    )
}
