// @ts-nocheck
import React, {Fragment, useEffect} from "react";
import {CardNumberElement, useElements, useStripe} from '@stripe/react-stripe-js';
import PaymentsApi from "../../../../../Service/PaymentsApiService";
import {PaymentMethod, StripeError} from "@stripe/stripe-js";
import {usePage} from "../../../../../Provider/PageProvider";
import {useHistory} from "react-router-dom";
import {usePageView} from "../../../../../Provider/PageViewProvider";

interface Props {
    active: boolean;
}

interface CallbackProps {
    paymentMethod?: PaymentMethod;
    error?: StripeError
}

export default function StripeCheckoutHandler({active}: Props) {
    const {registerEvent} = usePageView();

    const stripe = useStripe();
    const elements = useElements();
    const {makeOrder, contact, address, cartItems, setFormError} = usePage();
    const history = useHistory();
    const completeOrder = () => {
        if (active && !!stripe && !!elements) {
            PaymentsApi.completeOrder = (res) => {
                doCheckout();
            };
        }
    }
    useEffect(completeOrder, [active, stripe, elements, contact, address, cartItems])

    const doCheckout = () => {
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        // Get a reference to a mounted CardElement. Elements knows how
        // to find your CardElement because there can only ever be one of
        // each type of element.
        const cardElement = elements.getElement(CardNumberElement);

        // Use your card Element with other Stripe.js APIs
        registerEvent('stripe_order_start', 1);


        stripe
            .createPaymentMethod({
                type: 'card',
                card: cardElement,

            })
            .then((result) => {
                registerEvent('stripe_order_loaded', 1);

                if (result.error || !result.paymentMethod) {
                    registerEvent('stripe_order_error', 1);
                    setFormError(result.error.message);
                    // PagesApi.setPayment(orderId, 'error', result.error).then(() => {
                    // });
                } else {

                    setFormError('');
                    makeOrder('stripe', result.paymentMethod).then((res) => {
                        if (res.success === 1) {
                            registerEvent('stripe_order_success', 1);

                            const orderId = res.id;
                            history.push('/order/up/' + orderId);
                        } else {
                            registerEvent('stripe_order_error', 1);

                            setFormError(res.error);
                        }
                    });
                }
            });


    }


    return (
        <Fragment/>
    )
}
