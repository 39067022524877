import React, {Fragment} from "react";
import Field from "./Fields/Field";
import CountryField from "./Fields/CountryField";
import {usePage} from "../../../../Provider/PageProvider";
import PagesApi from "../../../../Service/PagesApiService";

export default function DeliveryAddress() {
    const {address, onAddressChange} = usePage();

    if (!address) {
        return <Fragment/>
    }

    const onFieldBlur = () => {
        PagesApi.checkZip(address['zip'], address['country']).then(res => {
            if ('state' in res && 'city' in res && !!res.state && !!res.city) {
                onAddressChange('multiple', JSON.stringify(res));
            }
        })
    }

    return (
        <Fragment>
            <h2>Step 3: <span>Delivery Address</span></h2>
            <CountryField val={address.country}/>
            <Field val={address.street} onChange={onAddressChange} title={"Street Address"} fieldKey={"street"} required={true}/>

            <div className="double a">
                <Field val={address.zip} onChange={onAddressChange} onFieldBlur={onFieldBlur} title={"Zip / Postal Code"} fieldKey={"zip"} required={true}/>

                <Field val={address.state} onChange={onAddressChange} title={"State / Province"} fieldKey={"state"} required={true}/>
            </div>

            <Field val={address.city} onChange={onAddressChange} title={"Town / City"} fieldKey={"city"} required={true}/>
        </Fragment>
    )
}
