import React, {Fragment} from "react";
import ProductBump from "./ProductBump";
import {Product} from "../../../../../Types/Objects";
import {usePage} from "../../../../../Provider/PageProvider";
import {isMobileOnly} from 'react-device-detect';
import {usePageView} from "../../../../../Provider/PageViewProvider";

interface Props {
    product: Product;
}

export default function ProductItem({product}: Props) {
    const {registerEvent} = usePageView();
    const {selectedProduct, setSelectedProduct, isProductChanged, setProductChanged, currencySymbol} = usePage();

    const selected = selectedProduct === product;

    const onClick = () => {
        if (!isProductChanged && selected) {
            setProductChanged(true);
        }
    }

    return (
        <li onClick={onClick}>
            {isMobileOnly && product.isPopular &&
            <span className={"most-pop-product-wrap"}>
                <span className="most-pop-product"><i className="icon-flame"></i> Most Popular</span>
                <span className={"triangle"}>
                    <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 -4.80825e-07L11 0L5.5 6L0 -4.80825e-07Z" fill="#F06565"/>
</svg>

                </span>
            </span>
            }
            <input type="radio" id={"product-" + product.id} name="lca" value={product.id}
                   onChange={() => {
                       setSelectedProduct(product);
                       registerEvent('product_select', product.id);
                   }} checked={selected}/>
            <label htmlFor={"product-" + product.id} className={isMobileOnly ? "mobile-only" : ""}>
                {isMobileOnly ?
                    <Fragment>
                        <span className="row" style={{marginBottom: 2}}>
                            <span className="overlay-a">{product.title}</span>
                        </span>

                        <span className={"row"} style={{justifyContent: 'space-between', alignItems: 'flex-end'}}>
                            <p className="price"
                               style={{marginLeft: 33}}>Only {currencySymbol}{product.price.toFixed(2)}
                                <span
                                    style={{textAlign: 'left'}}>Reg {currencySymbol}{product.regularPrice.toFixed(2)}</span>
                            </p>
                            <img src={product.image} alt={product.title} width="220" height="165"/>
                        </span>
                        {/*<span className="row">*/}
                        {/*    <span className="overlay-a">{product.description}</span>*/}
                        {/*    {product.isPopular &&*/}
                        {/*    <span className="overlay-b"><i className="icon-flame"></i> Most Popular</span>*/}
                        {/*    }*/}
                        {/*    <span></span>*/}
                        {/*</span>*/}
                        {/*<span className="row">*/}
                        {/*    <img src={product.image} alt={product.title} width="220" height="165"/>*/}
                        {/*    <span className="title">{product.title}</span>*/}
                        {/*    <p className="price">Only ${product.price.toFixed(2)}*/}
                        {/*        <span>Reg ${product.regularPrice.toFixed(2)}</span>*/}
                        {/*    </p>*/}
                        {/*</span>*/}
                    </Fragment>

                    :

                    <Fragment>
                        <img src={product.image} alt={product.title} width="220" height="165"/>
                        <span>
                            <span className="title">{product.title}</span>
                            <span className="overlay-a">{product.description}</span>
                        </span>
                        {product.isPopular &&
                        <span className="overlay-b"><i className="icon-flame"></i> Most Popular</span>
                        }
                    </Fragment>
                }

            </label>
            {!isMobileOnly &&
            <p className="price">Only {currencySymbol}{product.price.toFixed(2)}
                <span>Reg {currencySymbol}{product.regularPrice.toFixed(2)}</span>
            </p>}
            {product.bumpProduct && isProductChanged &&
            <ProductBump product={product.bumpProduct} orderProduct={product}/>
            }
        </li>
    )
}
