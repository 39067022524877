import React, {Fragment} from "react";
import {UpgradeOrderProduct} from "../../../../../Types/Objects";
import Product from "./Product";
import {usePage} from "../../../../../Provider/PageProvider";

export default function UpgradeOrder() {
    const {upgradeProducts} = usePage();

    // const {selectedUpgradeProducts} = usePage();
    // const products = upgradeProducts.filter((item: UpgradeOrderProduct) => {
    //     return selectedUpgradeProducts.indexOf(item) === -1;
    // })
    const products = upgradeProducts;

    if (!products || products.length === 0) {
        return <Fragment/>
    }

    return (
        <Fragment>
            <h2>Upgrade your order</h2>
            <ul className="list-upgrade">
                {products.map((item: UpgradeOrderProduct, index: number) => {
                        return <Product key={"upgrade-order-product-" + item.id} product={item}/>
                    }
                )}
            </ul>
        </Fragment>
    )
}
