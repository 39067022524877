import React from 'react';
import './App.css';
import {BrowserRouter, Route, Switch} from 'react-router-dom';
import CheckoutThemePage from "./Pages/CheckoutThemePage";
import {AuthProvider} from "./Provider/AuthProvider";
import UpsalePage from "./Themes/GreenPixel/Components/Content/Upsale/UpsalePage";
import PaymentConfirmPage from "./Themes/GreenPixel/Components/Content/Payment/PaymentConfirmPage";
import ConfirmPage from "./Themes/GreenPixel/Components/Content/Confirm/ConfirmPage";
import PaymentUpsaleConfirmPage from "./Themes/GreenPixel/Components/Content/Payment/PaymentUpsaleConfirmPage";
import Luckytail from "./Front/Luckytail";
import KidyStars from "./Front/KidyStars";
import Catsizor from "./Front/Catsizor";
import Getmagnetto from "./Front/Getmagnetto";
import {PageViewProvider} from './Provider/PageViewProvider';
import Luckytail2 from "./Front/Luckytail2";

function App() {

    const comp = () => {
        if (window.location.host === 'checkout.kidystars.com' || window.location.host === 'checkout.uvdrawing.com') {
            return KidyStars;
        } else if (window.location.host === 'checkout.catsizor.com') {
            return Catsizor;
        } else if (window.location.host === 'checkout.getmagnetto.com') {
            return Getmagnetto;
        } else if (window.location.host === 'checkout.lucky-tail.com') {
            return Luckytail2;
        } else {
            return Luckytail;
        }
    }

    return (
        <AuthProvider>
            <BrowserRouter basename={'/'}>
                <Switch>
                    <PageViewProvider>
                        <Route component={comp()} exact
                               path={"/"}/>
                        <Route component={comp()} exact
                               path={"/checkout"}/>

                        <Route component={CheckoutThemePage} path={"/cart/:url"}/>
                        <Route component={ConfirmPage} path={"/order/confirm/:serial"}/>

                        <Route component={UpsalePage} path={"/order/up/:serial"}/>

                        <Route component={PaymentConfirmPage} path={"/payment/confirm/:serial"}/>

                        <Route component={PaymentUpsaleConfirmPage}
                               path={"/payment-upsale/confirm/:serial/:mainSerial"}/>
                    </PageViewProvider>
                </Switch>
            </BrowserRouter>
        </AuthProvider>
    );
}

export default App;
