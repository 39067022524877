import React, {Fragment, useEffect, useState} from "react";
import PagesApi from "../../../../../Service/PagesApiService";
import {useHistory, useLocation, useParams} from "react-router-dom";
import {usePageView} from "../../../../../Provider/PageViewProvider";

export default function PaymentConfirmPage() {
    const {registerEvent} = usePageView();
    const history = useHistory();
    const {serial} = useParams();
    const location = useLocation();

    const [queryParams, setQueryParams] = useState<any>({});

    useEffect(() => {
        if (location.search.length > 0) {
            const q = Object.fromEntries(new URLSearchParams(location.search));
            setQueryParams(q);
        } else {
            setQueryParams({});
        }
    }, [location.search]);

    const pId = !!queryParams.PayerID ? queryParams.PayerID : '';
    const token = !!queryParams.token ? queryParams.token : '';

    const sendData = () => {
        registerEvent('payment_confirm_start', 1);
        PagesApi.setPayment(serial, 'paid', {id: pId, token}).then((res: any) => {
            history.push('/order/up/' + serial);
            registerEvent('payment_confirm_success', 1);
        });
    }

    const onPIdChange = () => {
        if (!!pId) {
            sendData();
        }
    }
    useEffect(onPIdChange, [pId]);

    return <Fragment/>
}
